.load_more {
    cursor: pointer;
    color: #000 !important;
    text-decoration: underline;
    position: absolute;
    float: left;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-weight: 600;
}

.hompage_slider, .similar_movies, .cast_section {
    padding: 30px 20px;
}

.hompage_slider{
    padding-top: 0px;
}

.cast_section {
    margin: 0px 20px;
}

.hompage_slider .slider_div {
    margin-bottom: 30px;
}

.movie_series_detail {
    margin-top: 50px;
}

.slider_img {
    border-radius: 20px;
}

.slider_img:hover {
    -ms-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    border-radius: 20px;
}

.mobile_slick_slider_wrapper_parent {
    display: none;
}

.mobile_slick_slider {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.slick-track{
    margin-left: 0px !important;
}

.mobile_slick_slider::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.desktop_slick_slider_parent .nav-link {
    cursor: pointer;
}

.season-selected {
    border: 4px;
    border-style: solid;
    border-color: yellow;
    /* width: 203px;
    z-index: 1000; */
}

.mobile_slick_slider .nav-link {
    flex: 0 0 29%;
    margin: 0px 5px;
}

.desktop_slick_slider_parent {
    display: block;
}

@media only screen and (max-width: 767px) {
    .slick-slide {
        min-height: 100% !important;
    }

    .slider_img:hover {
        -ms-transform: none;
        -webkit-transform: none;
        transform: none;
    }

    .desktop_slick_slider_parent {
        display: none;
    }

    .mobile_slick_slider_wrapper_parent {
        display: block;
        position: relative;
    }

    .season-selected {
        border: 4px;
        border-style: solid;
        border-color: yellow;
        width: 150px;
    }
    /* .slick-slider, .slick-list {
        height: 215px !important;
    } */

    .slider_heading {
        font-size: 18px;
    }

    .slider_div {
        margin-top: 20px;
    }

    .filter_results {
        margin: 0px 15px;
    }

    .slick-prev, .slick-next {
        display: none !important;
    }

    .hompage_slider {
        margin: 0px !important;
    }

    .hompage_slider, .similar_movies, .cast_section {
        padding: 30px 0px;
    }
}

@media only screen and (max-width: 1024px) {
    .slick-slide {
        min-height: 100% !important;
    }
    
    /* .slick-slider, .slick-list {
        height: 215px !important;
    } */

    .slider_heading {
        font-size: 18px;
    }

    .slider_div {
        margin-top: 20px;
    }
}