.detail_section .movie_details {
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.detail_section .movie_details .release_date span, .detail_section .movie_details .genre span {
    font-size: 30px;
    position: relative;
    top: -4px;
}

.cast_section .cast_wrapper .card {
    border: 1px solid transparent;
    background-color: transparent;
    /* min-height: 289px; */
    text-align: center;
}

.season_wrapper .card {
    border: 1px solid transparent;
    background-color: transparent;
    /* min-height: 289px; */
    text-align: center;
}

.season_wrapper_selected .card {
    border: 1px solid transparent;
    background-color: transparent !important;
    /* min-height: 289px; */    
    text-align: center;
}

.episode_wrapper .card {
    border: 1px solid transparent;
    background-color: transparent;
    /* min-height: 289px; */
    text-align: left;
    width: 200px;
}

.show-more {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
}

.accordion-button {    
    color: #fff !important;
}

/* .btn_wishlist {
    background-color: #ffe01b !important;
} */

.btn_wishlist_add {
    background-color: #ffe01b !important;
}

.btn_watch_now {
    border: 2px solid #ffe01b !important;
    font-weight: 700 !important;
    color: #fff !important;
    padding-left: 0px !important;
    padding-top: 0px !important;
    margin-right: 10px;
}

.btn_onboard {
    border: 2px solid #fff !important;
    font-weight: 700 !important;
    margin-right: 10px;
    color: #fff !important;
}

.detail_section .movie_details .genre small:last-child span {
    display: none;
}

.play_trailer {
    color: #ffe01b !important;
    cursor: pointer;
}

#trailerModal .modal-dialog {
    max-width: 70% !important;
    height: 70%;
}

.responsive-iframe {
    height: 100%;
    /* position: absolutge; */
    width: 100%;
}

.cast_parent, .watch_provider_slider_mob, .buy_rent_slider_mob, .watch_provider_slider {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
    scrollbar-width: none;
    /* Firefox */
}

.placeholder_div {
    position: relative;
}

.placeholder_div .person {
    font-size: 60px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cast_parent::-webkit-scrollbar, .watch_provider_slider_mob::-webkit-scrollbar, .buy_rent_slider_mob::-webkit-scrollbar, .watch_provider_slider::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.cast_child {
    flex: 0 0 12%;
    margin: 0px 10px;
}

.movie_details_desktop {
    display: block;
}

.movie_details_mob {
    display: none;
}

.rating-icon {
    font-size: 15px;
}

@media only screen and (max-width: 767px) {

    .genre{
        margin-bottom: 0px;
    }

    .watch_here_label{
        font-size: 12px !important;
    }

    .cast_section{
        margin-top: 0px !important;
    }


    #trailerModal .modal-dialog {
        max-width: 100% !important;
    }

    .cast_section .card-title, .cast_section .card-text {
        font-size: 11px;
    }

    .movie_details_desktop {
        display: none;
    }

    .movie_details_mob {
        display: block;
    }

    .detail_upper_btn_section{
        padding-bottom: 62px !important;
    }

    .movie_details_mobile_two{
        position: relative;
        font-size: 12px;
    }

    .movie_details_mobile_two .overview_label{
        font-size: 14px !important;
    }

    .detail_section .container-fluid {
        padding: 0px !important;
    }

    .btn_back_detail {
        margin-top: 0px !important;
    }

    .movie_series_detail {
        margin-top: 220px !important;
    }

    .cast_section, .similar_movies {
        margin: 0px !important;
        padding: 30px 0px !important;
    }

    .detail_upper_btn_section {
        margin-top: -70px !important;
    }

    .movie_details .title {
        margin-top: 10px;
        margin-bottom: 0px !important;
        font-size: 18px;
    }

    .movie_details .release_date {
        font-size: 14px;
    }

    .movie_details .overview {
        font-size: 14px;
    }

    .detail_poster {
        margin-top: -50px;
    }

    .detail_section .movie_details .release_date span, .detail_section .movie_details .genre span {
        font-size: 20px;
    }

    .cast_section .cast_row, .similar_movies .similar_movies_row {
        margin-right: 0.25rem !important;
        margin-left: 0.25rem !important;
    }

    .cast_section .slick-slide img {
        height: 100%;
    }

    .cast_section .cast_wrapper .slick-slider, .cast_section .cast_wrapper .slick-list {
        height: 300px !important;
    }

    .cast_section .cast_wrapper .slick-slider .card-body {
        padding: 10px 5px;
    }

    .cast_section .cast_wrapper .slick-slider .card-body .card-title {
        font-size: 13px;
    }

    .cast_section .cast_wrapper .slick-slider .card-body .card-text {
        font-size: 12px;
    }

    .placeholder_cast {
        min-height: 117px;
    }

    .movie_series_detail .movie_details {
        margin-top: -30px;
    }

    .movie_series_detail .title {
        font-size: 14px;
    }

    .movie_series_detail .genre small {
        display: inline-block;
        font-size: 12px;
    }

    .movie_detail_padding {
        padding: 0px !important;
    }

    .movie_series_detail .release_date, .movie_series_detail .play_trailer, .movie_series_detail .overview {
        font-size: 12px;
    }

    .movie_series_detail .overview_label, .movie_series_detail .watch_here_label {
        font-size: 14px;
    }

    .movie_series_detail .watch_provider_icon {
        width: 40px;
    }

    .cast_section .cast_parent .cast_child img {
        height: 117px;
        width: 85px;
    }

    .similar_movies{
        padding-top: 0px !important;
    }

    .similar_movies .container-fluid{
        padding: 0px !important;
    }

}

@media only screen and (max-width: 1024px) and (min-width: 767px) {
    .movie_series_detail .container-fluid {
        padding: 0px !important;
    }

    .cast_section .slick-slide img {
        height: 100%;
    }

    .cast_section .card-body {
        padding: 10px 7px;
    }

    .cast_section .slick-list, .cast_section .slick-slider {
        height: 310px !important;
    }

    .cast_section .slick-slide .card-title {
        font-size: 13px;
    }

    .search_results {
        padding: 10px 20px;
    }

    .cast_section .slick-slide .card-text {
        font-size: 12px;
    }

    .btn_back_detail, .btn_wishlist {
        margin-top: 110px;
    }

    /* .btn_back_detail {
        margin-left: 30px;
    }

    .btn_wishlist {
        margin-right: 30px;
    } */

    .movie_details .release_date, .movie_details .genre {
        font-size: 14px;
    }

    .detail_section .movie_details .release_date span, .detail_section .movie_details .genre span {
        font-size: 20px;
    }

    .movie_details .overview {
        font-size: 14px;
    }

    .movie_details .overview_label {
        margin-top: 10px !important;
    }

    .main_detail_row {
        margin: 0px !important;
    }

    .cast_row, .similar_movies_row {
        margin-left: 20px !important;
        margin-right: 20px !important;
    }
}