.fav_mobile i {
    color: #ffe01b;
}

.fav_mobile {
    margin-bottom: 0px;
    text-align: right;
    margin-top: -50px;
}

.fav_mobile {
    display: none;
}

.hompage_slider {
    margin: 0px 20px;
}

.detail_section_home {
    padding: 0px 0px 0px 0px;
}

.movie_details_mob #accordionExample{
    --bs-accordion-border-width: 0px;
}

.movie_details_mob #accordionExample .accordion-button {
    background-color: #16161a !important;
    font-size: 14px;
    color: #fff;
    padding-left: 0px;
    padding-bottom: 0px;
    box-shadow: none !important;
    border-radius: 0px !important;
}

.movie_details_mob #accordionExample .accordion-item{
    border: 0px;
}

.movie_details_mob #accordionExample .accordion-item .accordion-body{
    background-color: #16161a !important;
    font-size: 12px;
    color: #fff;
    padding-left: 0px;
    color: #94a1b2 !important;
}

.movie_details_mob #accordionExample .accordion-button:not(.collapsed)::after, .movie_details_mob #accordionExample .accordion-button::after {
    display: none;
}

.movie_details_mob #accordionExample .collapsed .fa-angle-up {
    display: none;
    margin-left: 5px;
}

.movie_details_mob #accordionExample .fa-angle-up{
    display: block;
    margin-left: 5px;
}

.movie_details_mob #accordionExample .accordion-button .fa-angle-down{
    display: none;
    margin-left: 5px;
}

.movie_details_mob #accordionExample .collapsed .fa-angle-down {
    display: block;
    margin-left: 5px;
}

@media screen and (max-width: 767px) {
    .detail_section_home .detail_poster {
        margin-top: 25px;
    }

    .detail_section_home {
        padding: 0px;
    }

    .detail_section_home .btn_wishlist {
        display: none;
    }

    .fav_mobile {
        display: block;
    }

    .detail_section_home .movie_details .title {
        font-size: 14px;
    }

    .detail_section_home .release_date {
        font-size: 12px;
    }

    .detail_section_home .genre small {
        font-size: 12px;
        display: inline-block;
    }

    .detail_section_home .overview_label {
        font-size: 14px;
        margin-top: 5px !important;
    }

    .detail_section_home .overview {
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: pre-wrap;
    }

    .detail_section_home .play_trailer, .watch_here {
        font-size: 14px;
    }

    .detail_section_home {
        margin-top: 0px !important;
    }
}