.navbar {
    background-color: #ffe01b !important;
    padding: 0px 50px !important;
    position: fixed !important;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.ReactFlagsSelect-module_selectBtn__19wW7 {
    border: 0px !important;
}

.search_input_form span {
    /* margin-left: -45px !important; */
    background: #000;
    color: #fff;
    z-index: 99;
    border: 1px solid #000;
    width: 200px;
    height: 100px;
    /* padding: 0px 23px; */
    border-radius: 30px !important;
    cursor: pointer;
}

.sign_in .material-symbols-sharp {
    font-size: 42px;
}

.desktop_flag_dropdown_span .ReactFlagsSelect-module_flagsSelect__2pfa2 {
    width: 200px !important;
}

.btn_sign_in {
    border: 3px solid #000 !important;
    color: #000 !important;
    margin-right: 20px;
}

.lower_nav h2 {
    text-align: center;
    font-weight: 700 !important;
    font-size: 50px;
    color: #fff !important;
    padding: 27px 0px;
}

.lower_nav .nav_links {
    display: flex;
    justify-content: space-around;
}

.lower_nav .nav_links li {
    list-style: none;
    color: #ffe01b;
    border: 3px solid #ffe01b;
    border-radius: 20px;
    margin-bottom: 10px;
    cursor: pointer;
}

.lower_nav .nav_links li a {
    padding: 5px 75px;
}

.lower_nav .nav_links li label {
    color: #000;
    font-weight: 600 !important;
}


.lower_nav {
    padding: 70px 0px 10px 0px;
    background-color: #16161a;
}

.lower_nav .dropdown .dropdown-menu li {
    padding: 0px !important;
    border: 0px !important;
}

.lower_nav .dropdown .dropdown-menu li a {
    font-weight: 600;
}

.lower_nav .dropdown .dropdown-menu {
    transform: translate3d(0px, 35px, 0px) !important;
    width: 600px !important;
    height: 250px;
    overflow-y: scroll;
    border-radius: 20px;
    padding-top: 0px;
}

.inner_list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0px;
}


.inner_list input {
    position: absolute;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    margin: 0px !important;
    height: 100%;
}

.inner_list label {
    border: 1px solid lightgray;
    border-radius: 20px;
    padding: 4px 13px;
}

.nav_links .active, .dropdown.active {
    border: 3px solid #68d197 !important;
    color: #68d197 !important;
}

.dropdown-item.active {
    color: #68d197 !important;
    background-color: transparent !important;
}

.sign_in_mobile {
    display: none;
}

.flag_mobile {
    display: none !important;
}

.filter_btn {
    display: none !important;
}

.btn_clear_all {
    background-color: #fff !important;
    color: #000 !important;
    font-weight: 600;
    border: 3px solid #fff !important;
    cursor: pointer;
    padding: 6px 75px;

}

.user_dropdown {
    left: 11px !important;
    top: 50px !important;
    width: 100%;
}

.user_dropdown li {
    cursor: pointer;
}

.user_dropdown li:hover {
    background-color: #ffe01b;
}

.user_dropdown_button {
    background-color: transparent !important;
    color: #000 !important;
    border: 0px !important;
}

.profile_image {
    border: 1px solid;
    border-radius: 50%;
    padding: 13.5px 13px;
    margin-right: 10px;
}

.place_holder {
    width: 20px;
    margin-right: 0px;
    margin-top: -7px;
}

.desktop_flag_dropdown {
    display: block;
}

.mobile_flag_dropdown {
    display: none;
}

.desktop_user_dropdown {
    display: block;
}

.mobile_user_dropdown {
    display: none !important;
}

#mySidenav {
    display: none !important;
}

.mobile_flag_span {
    display: none;
}

.desktop_flag_dropdown {
    background-color: transparent;
    border: 0px;
}

.btn_apply_filter {
    padding: 5px 75px;
    background-color: #ffe01b;
    color: #000 !important;
    font-weight: 700;
}

.detail_section_home .detail_poster {
    margin-left: 3rem;
}

.desktop_flag_dropdown, .mobile_flag {
    visibility: hidden;
}

.desktop_flag_dropdown_span .country_name {
    position: absolute;
    left: 25%;
}

.mobile_flag_span .country_name {
    position: absolute;
    left: 40%;
}

.desktop_flag_dropdown_span .country-list {
    width: 250px;
}

.modal {
    backdrop-filter: blur(10px) !important;
    background-color: rgba(54, 54, 54, .2) !important;
}

.search_input_form {
    margin-top: 15px;
}

.filter_mobile {
    display: none;
}

.watchprovider_search {
    color: #000;
    float: right;
    margin-right: 20px;
    margin-top: 11px;
}

.watchprovider_search_wrapper{
    height: 40px;
}

.watchprovider_search_close {
    color: #000;
    float: right;
    margin-right: 20px;
    margin-top: 11px;
    display: none;
    margin-left: 10px;
}

.watchprovider_search_field.input-group {
    width: 75% !important;
    margin-left: auto;
    margin-right: 20px;
    display: none;
}

.watchprovider_search_field input {
    border-radius: 20px;
    border-right: 0px;
    box-shadow: none !important;
    outline: 0px !important;
    border: 1px solid #ced4da;
    border-right: 0px;
}

.watchprovider_search_field span {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: transparent;
}

#my-tooltip{
    position: absolute !important;
    top: 40px !important;
    left:  0px;
    color: #fff;
    font-size: 12px !important;
}


@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}

@media only screen and (max-width: 767px) {

    .active_search {
        background-color: #68D197 !important;
        border: 1px solid #68D197 !important;
    }

    .filter_mobile {
        display: block;
        cursor: pointer;
    }

    .sign_in_mobile .ReactFlagsSelect-module_selectBtn__19wW7 {
        border: 0px;
        padding: 5px 0px !important;
    }

    .sign_in_mobile .ReactFlagsSelect-module_selectOptions__3LNBJ {
        left: -130px !important;
        width: 160px !important;
    }

    .sign_in_mobile .ReactFlagsSelect-module_selectBtn__19wW7::after, .sign_in_mobile .ReactFlagsSelect-module_selectBtn__19wW7 .ReactFlagsSelect-module_label__27pw9 {
        display: none !important;
    }

    .sign_in_mobile .ReactFlagsSelect-module_selectBtn__19wW7 {
        font-size: 18px !important;
    }

    .search_input_form {
        margin-top: 0px;
    }

    .lower_nav .nav_links {
        display: none;
    }

    .navbar-brand {
        margin: 0px !important;
        width: '100%';
    }

    .detail_section_home .detail_poster {
        margin-left: 0rem;
    }

    .profile_image_mobile {
        border-radius: 50%;
        padding: 8px 10px;
        margin-right: 10px;
    }

    .place_holder_mobile {
        width: 15px;
        margin-right: 0px;
        margin-top: -5px;
    }

    .mobile_user_dropdown .user_dropdown_button::after {
        display: none;
    }

    .mobile_user_dropdown .user_dropdown_button {
        padding: 0px;
        width: auto;
    }

    .desktop_flag_dropdown_span {
        display: none;
    }

    .mobile_flag_span {
        display: inline-block;
    }

    .mobile_flag_span .country-select {
        margin-top: 0px;
    }

    .mobile_flag {
        width: 120px;
    }

    #mySidenav {
        display: block !important;
    }

    .mobile_flag_dropdown {
        display: block;
    }

    .country-select {
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }

    /* .desktop_flag_dropdown{
        display: block;
    } */

    .navbar {
        padding: 0px 0px 0px 0px !important;
    }

    .nav_search {
        width: 200px !important;
        background-color: #fff !important;
        font-size: 14px !important;
    }

    .search_input_form span {
        background-color: #000;
        color: #fff;
        font-size: 14px;
        width: 84px;
        padding: 0px 18px;
        /* text-decoration: underline; */
    }

    .search_input_form {
        width: 100%;
    }

    .navbar-brand img {
        width: 152px;
        /* height: 50px; */
    }

    .sign_in {
        display: none;
    }

    /* .flag {
        display: none !important;
    } */

    .sign_in_mobile {
        margin-left: auto;
        display: flex;
        margin-top: 20px;
        margin-bottom: 15px;
    }

    .flag_mobile {
        display: inline-block !important;
    }

    .btn_sign_in {
        margin: 0px 4px;
        font-size: 11px !important;
        padding: 3px 7px !important;
        height: 38px;
    }

    .filter_btn {
        background-color: #ffe01b !important;
        width: 200px;
        border-radius: 20px !important;
        font-weight: 600 !important;
        display: block !important;
        margin: auto;
    }

    .active_filter {
        background-color: #68D197 !important;
        color: #fff !important;
    }

    .lower_nav {
        text-align: center;
        padding: 145px 20px 20px 20px;
    }

    .lower_nav h2 {
        font-size: 23px;
        color: #fff !important;
        margin-bottom: 0px !important;
    }

    .background_path_home .row {
        padding-top: 0px !important;
    }

    .btn-category, .btn-watchProvider {
        background-color: #fff !important;
        color: #000 !important;
        list-style: none;
        padding: 10px;
        width: 309px;
        border-radius: 0px !important;
        height: 47px;
        margin: 8px 0px 0px 0px;
    }

    .btn_apply {
        width: 60px;
        font-size: 14px !important;
        padding: 2px !important;
        border-radius: 10px !important;
        margin-left: auto;
        margin-right: 10px;
    }

    .sidenav_parent {
        height: 100vh;
        width: 0;
        position: fixed;
        z-index: 9999;
        top: 0;
        right: 0;
        backdrop-filter: blur(10px) !important;
        background-color: rgba(54, 54, 54, .2) !important;
        overflow-x: hidden;
        transition: 0.5s;
    }

    .sidenav {
        height: 100vh;
        width: 0;
        background: #000;
        overflow-x: hidden;
        transition: 0.5s;
    }

    .sidenav .sidenav_header {
        background-color: #ffe01b !important;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        padding: 10px 10px;
    }

    #mySidenav .dropdown-toggle::after {
        float: right;
        margin-top: 10px;
    }

    .sidenav .sidenav_footer {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .sidenav .sidenav_footer .btn_sidenav_result {
        background-color: #ffe01b !important;
        width: 100%;
        border-radius: 50px !important;
        font-weight: 700;
    }

    .sidenav .side_nav_title {
        color: #000 !important;
        font-weight: 700;
        margin-bottom: 0px !important;
    }

    .sidenav .dropdown-toggle {
        background-color: #FFFFFF !important;
        box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
        border-radius: 7px !important;
        color: #000 !important;
        border: 0px !important;
        margin-top: 20px;
        text-align: left;
    }

    .sidenav .dropdown-toggle.active {
        color: #68D197 !important;
        font-weight: 700;
    }

    .filter_section_sidenav {
        margin-top: 20px;
    }

    .btn_apply {
        border: 1px solid #68D197 !important;
        background-color: #68D197 !important;
        color: #fff !important;
        position: sticky;
        width: 100%;
        top: 0px;
        border-radius: 0px !important;
    }

    #country_dropdown, #country_dropdown:focus-visible {
        background: transparent !important;
        border: 0px !important;
    }

    .country-select ul {
        width: 200px !important;
    }

    .type, .category {
        font-size: 14px;
        padding-left: 10px;
        margin-bottom: 0px;
        padding-top: 10px;
    }

    .type-radio {
        margin-left: 10px;
    }

    .btn_apply_filter {
        background-color: #ffe01b !important;
        color: #000 !important;
        font-weight: 600;
        cursor: pointer;
        padding: 5px 75px;

    }

    .sidenav .filter_section_sidenav .inner_list li {
        list-style-type: none;
    }

    .sidenav .filter_section_sidenav .inner_list label, .sidenav .filter_section_sidenav label {
        font-size: 14px;
        color: #000 !important;
        padding: 4px 9px;
    }

    .sidenav .filter_section_sidenav .inner_list .active {
        border: 1px solid #68D197 !important;
        color: #68D197 !important;
    }

    .desktop_user_dropdown {
        display: none !important;
    }

    .mobile_user_dropdown {
        display: block !important;
    }

    .user_dropdown {
        top: 30px !important;
    }

    .user_dropdown {
        left: -135px !important;
    }

    .streaming_service_mobile .dropdown-menu {
        height: 300px;
        overflow-y: scroll;
    }

    .mobile_flag {
        padding-left: 45px;
    }
}

@media only screen and (max-width: 1023px) and (min-width: 767px) {
    .lower_nav .nav_links li a, .btn_clear_all, .btn_apply_filter {
        padding: 5px 25px;
    }

    .detail_section_home .detail_poster {
        margin-left: 0rem;
    }

    .lower_nav .dropdown .dropdown-menu {
        width: 450px !important;
    }

    .inner_list label {
        padding: 4px 10px;
        font-size: 14px;
    }

    .user_dropdown {
        left: -122px !important;
        top: 36px !important;
    }

    .mobile_user_dropdown button::after {
        display: none;
    }

    .mobile_user_dropdown {
        display: inline-flex !important;
    }

    .profile_image_mobile {
        border: 1px solid;
        border-radius: 50%;
        padding: 8px 10px;
        margin-right: 10px;
    }

    .place_holder_mobile {
        width: 15px;
        margin-right: 0px;
        margin-top: -5px;
    }

    .btn_clear_all {
        padding: 6px 25px;
    }

    .lower_nav .nav_links {
        padding: 0px 10px !important;
    }

    .lower_nav {
        padding: 165px 0px 1px 0px;
    }

    .lower_nav h2 {
        font-size: 30px;
        padding: 20px 65px;
    }

    .navbar-brand img {
        width: 200px !important;
        /* height: 60px !important; */
    }

    /* .sign_in_mobile svg{
        margin-top: 0px !important;
    } */

    /* .desktop_user_dropdown, .sign_in, .desktop_flag_dropdown_span {
        display: none !important;
    } */

    .sign_in_mobile {
        display: none;
    }

    /* .sign_in_mobile {
        margin-rigth: auto;
        display: flex;
        margin-top: 20px;
        margin-bottom: 15px;
    } */

    .mobile_flag_span {
        display: inline-block;
        width: 150px;
        top: 5px;
    }

    .mobile_flag_span .country-list {
        width: 200px;
    }

    .mobile_flag {
        width: 100%;
        height: 35px;
        background-color: transparent;
        border: 0px;
    }

    .search_input_form {
        width: 40%;
        margin-top: 10px;
    }

    .mobile_flag_span .country-list li span {
        font-size: 14px;
    }

    .sign_in_mobile .btn_sign_in {
        font-size: 14px;
        margin-top: -4px;
    }

    .navbar {
        padding: 20px 20px !important;
    }
}

@media only screen and (max-width: 1439px) and (min-width: 1024px) {
    .nav_search {
        width: 300px !important;
    }

    .detail_section_home .detail_poster {
        margin-left: 0rem;
    }

    .navbar {
        padding: 0px !important;
    }

    .lower_nav h2 {
        font-size: 40px;
    }

    .lower_nav .nav_links li a {
        padding: 5px 35px;
    }

    .btn_clear_all {
        padding: 5px 35px;
    }

    .btn_apply_filter {
        padding: 3px 35px;
    }
}